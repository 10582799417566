// .text()            => get vcard
// .split()           => create rows array
// .map() and .trim() => iterate every row in the array and remove whitespaces
// .join()            => join array to create a clean string
var vcard = $('#vcard').text().trim().split(/\r|\n|\r\n/g).map(function (row) {
    return row.trim();
}).filter(function (row) { return row.trim().length > 0; }).join('\n') + '\n';

$.ajax({
    async: true,
    url: '/api_js/v2/HelperUtils.asmx/QRCodeSpecialCharacters',
    data: {
        SystemPartner: ym.options.name,
        SecurityID: ym.options.securityID,
        Channel: ym.options.channel,
        QRCodeValue: JSON.stringify([vcard]),
        ReplaceSpecialCharacters: "false"
    },
    dataType: 'xml',
    type: 'post'
}).then(function (data) {
    var code = JSON.parse($(data).find('string').text());
    $('#qrcode').attr('src', 'data:image/png;base64,' + code.Values[0]);
});

ym.ready({ autocomplete: 5 }, function () {
    if (!ym.modules.autoComplete && sm.autocomplete.v5) {
        ym.modules.autoComplete = sm.autocomplete.v5.autoComplete;
    }
    // rewrite the backlink from local storage if exist
    if (sessionStorage.search) {
        var $backLink = $('.back-to-results');
        var backLinkUrl = $backLink.attr('href');
        var rootUrl = backLinkUrl.split('?')[0];
        $backLink.attr('href', rootUrl + sessionStorage.search);
    }

    var ac = ym.modules.autoComplete('#RouteBerechnen', { locales: ['de'] });
    var form = $(ac.element).closest('form')[0];

    function setInputValue(name, value) {
        var input = form.querySelector('[name="' + name + '"]');
        if (input) {
            input.value = value;
        }
    }

    ac.on('selected', function (item) {
        setInputValue('SourceX', item.geometry.coordinates[0]);
        setInputValue('SourceY', item.geometry.coordinates[1]);
        $(ac.element).closest('form').submit();
    });

    ac.element.addEventListener('input', function () {
        if (ac.element.value.trim() === '') {
            setInputValue('SourceX', '');
            setInputValue('SourceY', '');
        }
    });

    initializeMap();

    map.loadMap();

    window.addEventListener('resize', function () {
        var $mapContainer = $('#map-container');
        var width = $mapContainer.width();
        var height = $mapContainer.height();
        map.resizeMap({
            Width: width,
            Height: height
        });
    });

    var markerImage = '/presentation/filialfinder/areas/gtueMobile/content/img/gtue-logo_60.svg';
    var markerWidth = 40;
    var markerHeight = 30;
    if (config.Options.Mode === 3) {
        markerImage = '/presentation/filialfinder/areas/gtueMobile/content/img/Gtue_Vertragsanwalt.png';
        markerHeight = 40;
    }
    CenterMarker(config.Options.Center, new ym.common.CIcon(markerImage, {
        Width: markerWidth,
        Height: markerHeight
    }, {X: 20, Y: 15}));
});