function CenterMarker(GeoPoint, Icon) {
	if (!GeoPoint) GeoPoint = CenterMarker.GeoPoint;
	else CenterMarker.GeoPoint = GeoPoint;

	if (!Icon) {
		Icon = new ym.common.CIcon(
			'/Presentation/filialfinder/Content/img/icons/PoiDefaultLocation.gif',
			{ Width: 16, Height: 16 },
			{ X: 8, Y: 8 }
		);
	}

	map.removeMarker('CenterMarker');

	var marker = new ym.map.CMarker({
		Icon: Icon,
		Position: GeoPoint,
		LayerID: 'CenterMarker',
		ZIndex: 1000
	});

	map.addMarker(marker);
	map.showMarker(marker);

	return marker;
}
